import Header from "../layout/Header";
import {useState} from "react";
import logo from "../assets/logo.png";
import {API_HOST} from "../constants"
import {Link, useNavigate} from "react-router-dom";
import user from "../assets/icons/user.svg";
import phone from "../assets/icons/phone.svg";
import companyLocation from "../assets/icons/location.png";
import company from "../assets/icons/company.svg";
import role from "../assets/icons/role.svg";
import password from "../assets/icons/password.svg";

const Register = () => {
    const [errorMessages, setErrorMessages] = useState({});
    const [registerButtonText, setRegisterButtonText] = useState("Create Account");
    let navigate = useNavigate();
    const handleRegister = (event) => {
        event.preventDefault();
        const {uname, pass, mobile, firstname, lastname, company, role,location} = document.forms['register'];
        const data = {
            phone_number: mobile.value,
            email: uname.value,
            password: pass.value,
            first_name: firstname.value,
            last_name: lastname.value,
            company: company.value,
            location: location.value,
            job_role: role.value
        }
        setRegisterButtonText("Creating your account...")
        fetch(`${API_HOST}/accounts/create-user/`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    ok: response.ok,
                    json,
                })));
        }).then(({status, json, ok}) => {
            switch (status) {
                case 400:
                    setErrorMessages({
                        uname: json.email,
                        pass: json.password,
                        mobile: json.phone_number,
                        firstname: json.first_name,
                        lastname: json.last_name,
                        company: json.company,
                        role: json.job_role || json.non_field_errors,
                    });
                    break;
                case 201:
                case 200:
                    setErrorMessages({});
                    navigate("/verify-otp", {state: {email: uname.value}});
                    break;
                case 500:
                default:
                    setErrorMessages({})
                    handleUnexpected({status, json, ok});
            }
            setRegisterButtonText("Create Account")
        }).catch(err => {
            handleUnexpected(err);
        });
    }

    const handleUnexpected = (status, json, ok) => {
        setErrorMessages({"others": `Error registering user. Response Code: ${status}`});
    }
    const renderErrorMessage = (name) =>
        errorMessages[name] && (<div className="text-red-700">{errorMessages[name]}</div>);

    return (
        <>
            <div className="bg-fixed -z-50">
                <Header radius="xl:rounded-bl-full xl:rounded-br-full"/>
                <div className="flex">
                    <div className="login-form m-auto grid grid-flow-col auto-cols-max  mt-28 mb-10 rounded-3xl">
                        <div
                            className="absolute -mt-12 md:-mt-16 -ml-12 md:-ml-16 left-1/2 rounded-full">
                            <img src={logo} className="w-24 h-24 md:w-32 md:h-32" alt="Logo"/>
                        </div>
                        <div className="form bg-white rounded-3xl">
                            <form className="p-12 pb-2 md:p-24 md:pb-4 registration" name="register" onSubmit={handleRegister}>
                                <div className="flex flex-col items-center text-2xl p-3">
                                    <p className="w-auto">Register</p>
                                </div>
                                <div className="flex items-center text-lg">
                                    <img className="absolute ml-3" src={user} width="24" height="24"/>
                                    <input type="text" name="firstname"
                                           className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                           placeholder="First Name"/>
                                </div>
                                {renderErrorMessage("firstname")}
                                <div className="flex items-center text-lg mt-6 md:mt-8">
                                    <img className="absolute ml-3" src={user} width="24" height="24"/>

                                    <input type="text" name="lastname"
                                           className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                           placeholder="Last Name"/>
                                </div>
                                {renderErrorMessage("lastname")}
                                <div className="flex items-center text-lg mt-6 md:mt-8">
                                    <img className="absolute ml-3" src={user} width="24" height="24"/>
                                    <input type="text" name="uname"
                                           className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                           placeholder="Email Address"/>
                                </div>
                                {renderErrorMessage("uname")}
                                <div className="flex items-center text-lg mt-6 md:mt-8">
                                    <img className="absolute ml-3" src={password} width="24" height="24"/>
                                    <input type="password" name="pass"
                                           className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                           placeholder="Password"/>
                                </div>
                                {renderErrorMessage("pass")}
                                <div className="flex items-center text-lg mt-6 md:mt-8">
                                    <img className="absolute ml-3" src={phone} width="24" height="24"/>

                                    <input type="tel" name="mobile"
                                           className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                           placeholder="Mobile Number"/>
                                </div>
                                {renderErrorMessage("mobile")}
                                <div className="flex items-center text-lg mt-6 md:mt-8">
                                    <img className="absolute ml-3" src={company} width="24" height="24"/>
                                    <input type="text" name="company"
                                           className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                           placeholder="Your Company"/>
                                </div>
                                {renderErrorMessage("company")}
                                <div className="flex items-center text-lg mt-6 md:mt-8">
                                    <img className="absolute ml-3" src={companyLocation} width="24" height="24"/>

                                    <input type="text" name="location"
                                           className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                           placeholder="Company Location/Branch"/>
                                </div>
                                {renderErrorMessage("location")}
                                <div className="flex items-center text-lg mt-6 md:mt-8">
                                    <img className="absolute ml-3" src={role} width="24" height="24"/>

                                    <input type="text" name="role"
                                           className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                           placeholder="Your Role"/>
                                </div>
                                {renderErrorMessage("role")}
                                <div className="mt-6">
                                    <input value={registerButtonText}
                                           className="bg-gradient-to-b from-gray-700 to-gray-900 font-medium p-2 md:p-4 text-white uppercase w-full rounded"
                                           type="submit"/>
                                    {renderErrorMessage("others")}
                                </div>
                                <p className="text-center text-sm text-gray-500 mt-4">Already got account?
                                    <Link to={"/login"}
                                          className="font-semibold text-gray-600 hover:underline focus:text-gray-800 focus:outline-none">
                                        Login Now</Link>

                                </p>
                                <p className="text-center text-sm text-gray-500 mt-4">
                                    <Link to={"/forgot-password"}
                                          className="font-semibold text-gray-600 hover:underline focus:text-gray-800 focus:outline-none">
                                        Forgot Password?</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;