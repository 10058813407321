import {useState} from "react";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";
import user from "../../assets/icons/user.svg";

const DashboardNav = () => {
    const [navClose, toggleNav] = useState(window.screen.width > 650);
    const [cookies] = useCookies(['email', 'name']);

    const path = window.location.pathname.split('/')[1];

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    const showNav = () => {
        toggleNav(!navClose);
    };
    return (
        <>
            <div className="bg-fixed">
                <div className="nav flex flex-col" style={{height: "100vh"}}>
                    <div className="rounded-r flex justify-between w-full p-2 items-center"
                         style={{background: "#00801f"}}>
                        <Link to={'/'}>
                            <div className="flex justify-between  items-center space-x-3">
                                <img
                                    src={require("../../assets/logo.png")}
                                    className="w-12 12 rounded-full border border-secondary "
                                    alt=""
                                />
                                <p className="text-2xl leading-6 text-white">Sustaincred</p>
                            </div>
                        </Link>
                        <div aria-label="toggler" className="flex justify-center items-center">
                            <button aria-label="open" id="open" onClick={() => showNav()}
                                    className={classNames("focus:outline-none focus:ring-2", navClose ? "hidden" : "")}>
                                <svg className="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 6H20" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M4 12H20" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M4 18H20" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </button>
                            <button aria-label="close" id="close" onClick={() => showNav()}
                                    className={classNames("focus:outline-none focus:ring-2", navClose ? "" : "hidden")}>
                                <svg className="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M6 6L18 18" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div id="Main"
                         className={classNames("transform  xl:translate-x-0 flex-grow ease-in-out transition duration-500 flex justify-start items-start w-full sm:w-64 flex-col", navClose ? "" : "hidden")}
                         style={{background: "#00801f"}}>
                        <div
                            className="mt-6 flex flex-col justify-start items-center flex-grow pl-4 w-full border-gray-600 border-b space-y-3 pb-5 ">
                            <button
                                className="flex jusitfy-start items-center space-x-6 w-full  focus:outline-none  focus:text-indigo-400  text-white rounded ">
                                <svg className="fill-stroke" viewBox="0 0 1024 1024" width="24">
                                    <path d="M531.8 385v483.3h0.1V385h-0.1z" fill="#FFFFFF"/>
                                    <path
                                        d="M670.9 497.1h86v16h-86zM670.9 625.1h86v16h-86zM233.9 241.1h86v16h-86zM384 241.1h86v16h-86zM233.9 369h86v16h-86zM384 369h86v16h-86zM234 497.5h86v16h-86zM384 497.2h86v16h-86z"
                                        fill="#FFFFFF"
                                    />
                                    <path
                                        d="M398.3 704.4c-11.9-11.9-28.4-19.3-46.5-19.3-36.2 0-65.8 29.6-65.8 65.8v117.4h20V750.9c0-12.2 4.8-23.6 13.5-32.3 8.7-8.7 20.2-13.5 32.3-13.5 12.2 0 23.6 4.8 32.3 13.5 8.7 8.7 13.5 20.2 13.5 32.3v117.4h20V750.9c0-18.1-7.4-34.5-19.3-46.5z"
                                        fill="#FFFFFF"/>
                                    <path d="M575.8 429v437.9h0.1V429h-0.1zM286.2 868.3h131.6-131.6z"/>
                                    <path
                                        d="M896 868.3V385H575.9V111.6H128v756.7H64v44h896v-44h-64z m-364.1 0H172V155.6h359.9v712.7z m320.1-1.5H575.8V429H852v437.8z"
                                        fill="#FFFFFF"
                                    />
                                </svg>
                                <p className="text-base leading-4"><span
                                    className={path === "company" ? "text-cyan-300" : ""}><Link to={"/company"}>My Company</Link></span>
                                </p>
                            </button>
                            <button
                                className="flex jusitfy-start items-center w-full  space-x-6 focus:outline-none text-white focus:text-indigo-400 rounded">
                                <img src={user} width="24" height="24" className="filter-white"/>
                                <p className="text-base leading-4"><span
                                    className={path === "documents" ? "text-cyan-300" : ""}><Link to={"/documents"}>My Documents</Link></span>
                                </p>
                            </button>
                            <button
                                className="flex jusitfy-start items-center space-x-6 w-full  focus:outline-none  focus:text-indigo-400  text-white rounded ">
                                <svg className="fill-stroke " width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z"
                                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M19 4H15C14.4477 4 14 4.44772 14 5V9C14 9.55228 14.4477 10 15 10H19C19.5523 10 20 9.55228 20 9V5C20 4.44772 19.5523 4 19 4Z"
                                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z"
                                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z"
                                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                                <p className="text-base leading-4"><span
                                    className={path === "dashboard" ? "text-cyan-300" : ""}><Link to={"/dashboard"}>Analytics Dashboard</Link></span>
                                </p>
                            </button>
                            <div className="h-0.5 bg-gray-600" style={{width: "100%"}}/>
                            <button
                                className="flex jusitfy-start items-center w-full  space-x-6 focus:outline-none text-white focus:text-indigo-400 rounded">
                                <img src={user} width="24" height="24" className="filter-white"/>
                                <p className="text-base leading-4"><span
                                    className={path === "change-password" ? "text-cyan-300" : ""}><Link to={"/change-password"}>Change Password</Link></span>
                                </p>
                            </button>
                            <button
                                className="flex jusitfy-start items-center w-full  space-x-6 focus:outline-none text-white focus:text-indigo-400 rounded">
                                <img src={user} width="24" height="24" className="filter-white"/>
                                <p className="text-base leading-4"><span
                                    className={path === "logout" ? "text-cyan-300" : ""}><Link to={"/logout"}>Logout</Link></span>
                                </p>
                            </button>
                            <button
                                className="flex jusitfy-start items-center w-full  space-x-6 focus:outline-none text-white focus:text-indigo-400 rounded">
                                <img src={user} width="24" height="24" className="filter-white"/>
                                <p className="text-base leading-4"><span
                                    className={path === "logout-all" ? "text-cyan-300" : ""}><Link to={"/logout-all"}>Logout All Devices</Link></span>
                                </p>
                            </button>
                        </div>

                        <div className="flex justify-between items-center w-full p-2">
                            <div className="flex justify-center items-center  space-x-2">
                                {cookies.name && <div className="circle">{cookies.name.charAt(0)}</div>}
                                <div className="flex justify-start flex-col items-start">
                                    <p className="cursor-pointer text-sm leading-5 text-white">{cookies.name}</p>
                                    <p className="cursor-pointer text-xs leading-3 text-gray-300">{cookies.email}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default DashboardNav;
