import React, {useEffect, useState} from "react";
import DashboardNav from "./components/DashboardNav";
import {useLocation, useNavigate} from "react-router-dom";
import {API_HOST} from "../constants";
import {useCookies} from "react-cookie";

const LogOut = () => {
    const [devices, setDevices] = useState(window.location.pathname.split('/')[1]);
    const [cookies, setCookie] = useCookies(['authtoken']);
    const [errorMessages, setErrorMessages] = useState({});

    const location = useLocation();
    const navigate = useNavigate();

    function logout() {
        setCookie("authtoken", null);
        setCookie("expiry", null);
        setCookie("email", null);
        setCookie("name", null);
    }

    useEffect(() => {
        let devices_value = window.location.pathname.split('/')[1];
        setDevices(devices_value);
        let apiName = (devices_value === "logout-all") ? "logout-all" : "logout";
        fetch(`${API_HOST}/accounts/${apiName}/`, {
            method: "POST",
            headers: {
                'Authorization': 'Token ' + cookies.authtoken
            }
        }).then(response => {
            if (response.status === 204) {
                logout();
                navigate("/");
                return;
            }
            response.text().then((body, response) => {
                try {
                    let json = JSON.parse(body);
                    if (json.detail === "Invalid token.") {
                        logout();
                    }
                } catch {
                    setErrorMessages({"logout": "Error logging you out"})
                }
            });
        });
    }, [location]);
    const renderErrorMessage = (name) =>
        errorMessages[name] && (<div className="text-red-700">{errorMessages[name]}</div>);

    return (
        <>
            <div className="">
                <div className="absolute left-0 top-32 m-3 md:ml-96 text-white glossy p-12 min-w-[50%]">
                    Please wait while we are logging you out {devices === "logout-all" ? "of all devices" : ""}...
                    {renderErrorMessage("logout")}
                </div>
                <DashboardNav/>
            </div>
        </>
    );
};

export default LogOut;