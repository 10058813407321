import {useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import Login from "./dashboard/Login";
import MyDashboard from "./dashboard/MyDashboard";
import Register from "./dashboard/Register";
import ForgotPassword from "./dashboard/ForgotPassword";
import VerifyOTP from "./dashboard/VerifyOTP";
import Private from "./components/ProtectedRoute";
import MyCompany from "./dashboard/MyCompany";
import MyDocuments from "./dashboard/MyDocuments";
import LogOut from "./dashboard/LogOut";
import ChangePassword from "./dashboard/ChangePassword";
import TermsAndConditions from "./pages/TermsAndConditions";
import Privacy from "./pages/Privacy";

const App = () => {
    const ScrollTop = () => {
        const {pathname} = useLocation();
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);
        return null;
    };
    return (
        <>
            <ScrollTop/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/blogs" element={<Blogs/>}/>
                <Route path="/blogs/:id" element={<Blog/>}/>
                <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                <Route path="/privacy-policy" element={<Privacy/>}/>

                <Route path="/login" element={<Private Component={Login} isLoginPage={true}/>}/>
                <Route path="/register" element={<Register/>} isLoginPage={true}/>
                <Route path="/forgot-password" element={<ForgotPassword/>} isLoginPage={true}/>
                <Route path="/verify-otp" element={<VerifyOTP/>} isLoginPage={true}/>

                <Route path="/company" element={<Private Component={MyCompany}/>}/>
                <Route path="/change-password" element={<Private Component={ChangePassword}/>}/>
                <Route path="/dashboard" element={<Private Component={MyDashboard}/>}/>
                <Route path="/documents" element={<Private Component={MyDocuments}/>}/>
                <Route path="/logout" element={<Private Component={LogOut}/>}/>
                <Route path="/logout-all" element={<Private Component={LogOut}/>}/>
                <Route
                    path="*"
                    element={
                        <div className="flex items-center justify-center min-h-screen md:text-xl lg:text-3xl">
                            404 | Not Found
                        </div>
                    }
                />
            </Routes>
        </>
    );
};

export default App;
