import {API_HOST, validateInput} from "../../constants";
import FileUploadInput, {INPUT_TYPES} from "./FileUploadInput";
import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {renderErrorMessage} from "../utils";

const FuelConsumption = ({year}) => {
    const FUEL_TYPES = ["Diesel", "Coal", "Petrol", "Butane", "Propane", "Others"]
    const FUEL_TYPE_UNITS = ["Ltrs", "Kgs", "Ltrs", "Kgs", "Kgs", "Units"]
    const [cookies] = useCookies(['authtoken']);
    const [files, setFiles] = useState([]);
    const [data, setData] = useState(null);
    const [fuelType, setFuelType] = useState(FUEL_TYPES[0]);
    const [loading, setLoading] = useState(true);
    const [preview, setPreview] = useState(true);
    const [errorMessages, setErrorMessages] = useState({});

    const fetchData = async () => {
        try {
            const formData = new FormData();
            formData.append('financial_year', year)
            const response = await fetch(`${API_HOST}/accounts/fuel-consumption/get/`, {
                method: "POST",
                headers: {
                    'Authorization': 'Token ' + cookies.authtoken
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const result = await response.json();
            setData(result);
        } catch (error) {
            setErrorMessages({previous_data: error.message});
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [year]);

    const setFuelTypeValue = (event) => {
        setFuelType(event.target.value);
    };

    const handleSubmission = (event) => {
        event.preventDefault();
        const {unitType, consumption} = document.forms['fuel-form'];
        const formData = new FormData();
        formData.append('financial_year', year)
        formData.append('fuel_type', fuelType === "Others" ? unitType.value : fuelType)
        formData.append('consumption', consumption.value)
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        fetch(`${API_HOST}/accounts/fuel-consumption/update/`, {
            method: "POST",
            headers: {
                'Authorization': 'Token ' + cookies.authtoken
            },
            body: formData
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    json,
                })));
        }).then(({status, json}) => {
            if (status !== 200) setErrorMessages(json)
            else {
                fetchData();
                setFiles([])
                setErrorMessages({detail: 'Data is submitted successfully'})
                setPreview(!preview)
            }
        });
    }

    return (
        <div className="text-left p-2">

            <div className="p-2">
                <form onSubmit={handleSubmission} name="fuel-form">
                    <p className="text-center">Please update Fuel Consumption for Financial Year {year}. </p><br/>
                    <div>
                        <p>You can upload multiple types of fuel consumption.</p><br/>
                        <span>Please select Fuel Type: </span>
                        <select value={fuelType} name="fuel" onChange={setFuelTypeValue} className="text-black">
                            {FUEL_TYPES.map((option, i) => {
                                return (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                )
                            })}
                        </select>
                        {fuelType === "Others" ? (
                            <span>
                                <input type="text" maxLength="20" name="unitType" placeholder="Fuel Name"
                                       className="text-black" style={{height: "inherit", margin: "0 10px"}}/>
                            </span>
                        ) : ""}
                        <br/>
                    </div>
                    <div>
                        <span>Input consumption for the year in {FUEL_TYPE_UNITS[FUEL_TYPES.indexOf(fuelType)]} :  </span>
                        <input placeholder="Fuel in kw/h" name="consumption" type="number"
                               onKeyPress={validateInput} onChange={validateInput}
                               className="bg-[#999999] w-18" min="0" max="99999" autoFocus={true}/>
                        {renderErrorMessage('consumption', errorMessages)}
                        <br/><br/>
                    </div>

                    <FileUploadInput onChange={setFiles} togglePreview={preview}/>
                    {renderErrorMessage('files', errorMessages)}

                    <center>
                        <input type="submit" className="bg-green-700 rounded-2xl py-2 px-8 text-xl" value="Submit"/>
                        <br/>
                        {renderErrorMessage('detail', errorMessages)}
                        {renderErrorMessage('non_detail_fields', errorMessages)}
                        {data ? (
                            <p>Note: Submit will override any consumption updated previously for {fuelType}</p>) : ""}
                    </center>
                </form>
            </div>
            {data ?
                (<span>
                    <hr className="m-3"/>
                    <p className="text-center">Previously Submitted Fuel Consumption
                        for financial {year} </p>
                    <br/>
                    <div className="w-full" style={{overflowX: "auto"}}>
                    <table className="bills">
                        <thead>
                            <tr>
                                <th>Fuel Type</th>
                                <th>Consumption</th>
                                <th>Last Updated</th>
                                <th>Supporting Documents</th>
                            </tr>
                        </thead>
                        <tbody>
                        {/* eslint-disable-next-line array-callback-return */}
                        {data?.map((item, i) => {
                            return (
                                <tr>
                                    <td>{item.fuel_type}</td>
                                    <td>{item.consumption} {FUEL_TYPE_UNITS[FUEL_TYPES.indexOf(item.fuel_type)] || "Units"}</td>
                                    <td>{item.update_time}</td>
                                    <td>
                                        <ul className="list-disc ml-3">
                                            {JSON.parse(item.files_list?.replace(/'/g, '"'))?.map((option, i) => {
                                                return (
                                                    <li key={i}>
                                                        <a href={"server/media/" + option} target={"_blank"}
                                                           className="text-yellow-300 hover:underline">{option}</a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </span>) : ""}
            {loading ? (<div>Loading...</div>) : ""}
            {renderErrorMessage('previous_data', errorMessages)}
        </div>
    );
}
export default FuelConsumption;