import {API_HOST, MONTHS} from "../../constants";
import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {renderErrorMessage} from "../utils";

const CompanyVehicles = ({year}) => {
    const VEHICLE_TYPE = ["Sedan", "SUV", "Truck"]
    const [cookies] = useCookies(['authtoken']);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [errorMessages, setErrorMessages] = useState({});
    const fetchData = async () => {
        try {
            const formData = new FormData();
            formData.append('financial_year', year)
            const response = await fetch(`${API_HOST}/accounts/vehicles/get/`, {
                method: "POST",
                headers: {
                    'Authorization': 'Token ' + cookies.authtoken
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const result = await response.json();
            setData(result);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [year]);

    const submitVehicles = (event) => {
        event.preventDefault();
        const {vehicle, rto} = document.forms['vehicles'];
        fetch(`${API_HOST}/accounts/vehicles/update/`, {
            method: "POST",
            headers: {
                'Authorization': 'Token ' + cookies.authtoken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                financial_year: year,
                vehicle_type: vehicle.value,
                vehicle_number: rto.value
            })
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    json,
                })));
        }).then(({status, json}) => {
            if (status !== 200) setErrorMessages(json)
            else {
                fetchData();
                setErrorMessages({detail: 'Data is submitted successfully'})
            }
        });
    }
    return (
        <div className="p-4">
            <p> Submit Company Vehicles you have operated on Financial Year {year}</p>
            <form className="text-left mt-8" name="vehicles" onSubmit={submitVehicles}>
                <p>You can select multiple vehicles.</p><br/>
                <div><span>Select Type of Vehicle:</span>
                    <select name="vehicle" className="text-black">
                        {VEHICLE_TYPE.map((option, i) => {
                            return (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            )
                        })}
                    </select>
                    {renderErrorMessage('vehicle_type', errorMessages)}
                </div>
                <div>
                    <span>Please input vehicle RTO Number: </span>
                    <input type="text" name="rto" minLength="5" maxLength="14" className="text-black"
                           style={{minWidth: "20px", height: "inherit"}} placeholder="RTO Number"/>
                    {renderErrorMessage('vehicle_number', errorMessages)}
                    <center>
                        <input type="submit" className="bg-green-700 rounded-2xl px-8 text-xl" value="Submit"/>
                        <br/>
                        {renderErrorMessage('detail', errorMessages)}
                        {renderErrorMessage('non_field_errors', errorMessages)}
                    </center>
                </div>
            </form>

            {data ?
                (<span>
                    <hr className="m-3"/>
                    <p className="text-center">Previously Submitted vehicles
                        for financial {year} </p>
                    <br/>
                    <div className="w-full" style={{overflowX: "auto"}}>
                    <table className="bills">
                        <thead>
                            <tr>
                                <th>Vehicle Type</th>
                                <th>RTO Number:</th>
                                <th>Last Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                        {/* eslint-disable-next-line array-callback-return */}
                        {data?.map((item, i) => {
                            return (
                                <tr>
                                    <td>{item.vehicle_type}</td>
                                    <td>{item.vehicle_number}</td>
                                    <td>{item.update_time}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </span>) : ""}
            {loading ? (<div>Loading...</div>) : ""}
            {error ? (<div>Error loading data: {error}</div>) : ""}
        </div>
    );
}
export default CompanyVehicles;