import React, {useState} from "react";
import DashboardNav from "./components/DashboardNav";
import {useCookies} from "react-cookie";
import treeMD from "../assets/logo.png";
import {useNavigate} from "react-router-dom";
import {renderErrorMessage} from "./utils";
import password from "../assets/icons/password.svg";
import {API_HOST} from "../constants";

const MyDashboard = () => {

    const [cookies, setCookie] = useCookies(['authtoken']);
    const [errorMessages, setErrorMessages] = useState({});
    let navigate = useNavigate();

    function logout() {
        setCookie("authtoken", null);
        setCookie("expiry", null);
        setCookie("email", null);
        setCookie("name", null);
    }

    const searchParams = new URLSearchParams(window.location.search);
    const reset = searchParams.get('reset');

    const handleForgotPassword = (event) => {
        event.preventDefault()
        const {old_password, new_password} = document.forms['change-password'];

        const data = {
            old_password: old_password.value,
            new_password: new_password.value,
            reset: reset === "true"
        }
        fetch(`${API_HOST}/accounts/change-password/`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + cookies.authtoken
            }
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    ok: response.ok,
                    json,
                })));
        }).then(({status, json, ok}) => {
            if (status === 200) {
                logout();
                navigate("/login?password-change=true");
            }
            setErrorMessages(json);
        });
    }
    return (
        <>
            <div className="">
                <div className="absolute left-0 top-24  p-4 dashboard-container
                ml-[5%] mr-[5%] md:ml-72 min-w-[90%] md:min-w-[50%]">
                    <div className="flex">
                        <div className="login-form m-auto grid grid-flow-col auto-cols-max  mt-28 mb-10 rounded-3xl">
                            <div
                                className="absolute -mt-12 md:-mt-16 -ml-12 md:-ml-16 left-1/2 rounded-full">
                                <img src={treeMD} className="w-24 h-24 md:w-32 md:h-32" alt="Logo"/>
                            </div>
                            <div className="form bg-white rounded-3xl registration">
                                <form className="p-12 pb-2 md:p-24 md:pb-4" name="change-password"
                                      onSubmit={handleForgotPassword}>
                                    <div className="flex flex-col items-center text-2xl p-3">
                                        <p className="w-auto">Change Password</p>
                                    </div>
                                    {reset === "true" ? (
                                        <div className="flex items-center text-lg mt-6 md:mt-8">
                                            <img className="absolute ml-3" src={password} width="24" height="24"/>
                                            <input type="password" name="old_password"
                                                   className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                                   placeholder="Old Password"/>
                                        </div>) : ""}
                                    {renderErrorMessage("old_password", errorMessages)}
                                    <div className="flex items-center text-lg mt-6 md:mt-8">
                                        <img className="absolute ml-3" src={password} width="24" height="24"/>
                                        <input type="password" name="new_password"
                                               className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                               placeholder="New Password"/>
                                    </div>
                                    {renderErrorMessage("new_password", errorMessages)}
                                    <div className="mt-6">
                                        <input
                                            className="bg-gradient-to-b from-gray-700 to-gray-900 font-medium p-2 md:p-4 text-white uppercase w-full rounded"
                                            type="submit"/>
                                        {renderErrorMessage("detail", errorMessages)}

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
                <DashboardNav/>
            </div>
        </>
    );
};

export default MyDashboard;