import {API_HOST, MONTHS} from "../../constants";
import {useCookies} from "react-cookie";
import React, {useEffect, useState} from "react";
import {renderErrorMessage} from "../utils";
import FileUploadInput, {INPUT_TYPES} from "./FileUploadInput";

const SupplyChain = ({month, year, section, section_id}) => {
    const [cookies] = useCookies(['authtoken']);
    const [files, setFiles] = useState([]);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [preview, setPreview] = useState(true);
    const [errorMessages, setErrorMessages] = useState({});

    const fetchData = async () => {
        try {
            const formData = new FormData();
            formData.append('financial_year', year)
            formData.append('data_type', section_id)
            const response = await fetch(`${API_HOST}/accounts/additional-details/get/`, {
                method: "POST",
                headers: {
                    'Authorization': 'Token ' + cookies.authtoken
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const result = await response.json();
            setData(result);
        } catch (error) {
            setErrorMessages({previous_data: error.message});
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [year]);

    const handleSubmission = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('financial_year', year)
        formData.append('data_type', section === "Supply Chain Details" ? "supply_chain" : "finance")
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        fetch(`${API_HOST}/accounts/additional-details/update/`, {
            method: "POST",
            headers: {
                'Authorization': 'Token ' + cookies.authtoken
            },
            body: formData
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    json,
                })));
        }).then(({status, json}) => {
            if (status !== 200) setErrorMessages(json)
            else {
                fetchData();
                setFiles([])
                setErrorMessages({detail: 'Data is submitted successfully'})
                setPreview(!preview)
            }
        });
    }

    return (
        <div className="text-left p-2">
            <div className="p-2">
                <form onSubmit={handleSubmission} name="additional-form">
                    <p className="text-center">Please upload {section} for Financial Year {year}. </p><br/>

                    <FileUploadInput onChange={setFiles} togglePreview={preview} maxSize={5 * 1024 * 1024}
                                     maxFiles={100} inputType={INPUT_TYPES.SHEETS}/>
                    {renderErrorMessage('files', errorMessages)}

                    <center>
                        <input type="submit" className="bg-green-700 rounded-2xl py-2 px-8 text-xl" value="Submit"/>
                        <br/>
                        {renderErrorMessage('detail', errorMessages)}
                        {renderErrorMessage('non_detail_fields', errorMessages)}
                        {data ? (<p>Note: Submit will override any consumption updated previously</p>) : ""}
                    </center>
                </form>
            </div>
            {data ?
                (<span>
                    <hr className="m-3"/>
                    <p className="text-center">Previously Submitted Way bills for financial {year} </p>
                    <br/>
                    <div className="w-full" style={{overflowX: "auto"}}>
                    <table className="bills">
                        <thead>
                            <tr>
                                <th>Last Updated</th>
                                <th>Supporting Documents</th>
                            </tr>
                        </thead>
                        <tbody>
                        {/* eslint-disable-next-line array-callback-return */}
                        {data?.map((item, i) => {
                            return (
                                <tr>
                                    <td>{item.update_time}</td>
                                    <td>
                                        <ul className="list-disc ml-3">
                                            {JSON.parse(item.files_list?.replace(/'/g, '"'))?.map((option, i) => {
                                                return (
                                                    <li key={i}>
                                                        <a href={"server/media/" + option}
                                                           className="text-yellow-300 hover:underline">{option}</a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    </div>
                </span>) : ""}
            {loading ? (<div>Loading...</div>) : ""}
            {renderErrorMessage('previous_data', errorMessages)}
        </div>
    );
}
export default SupplyChain;