import React, {useState} from "react";
import {DASHBOARD_YEARS, MONTHS} from "../../constants";

const MonthYearComponent = ({onSelect}) => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const handleYearSelect = (event) => {
        const selectedValue = event.target.value;
        setSelectedYear(selectedValue);
        onSelect(0, selectedValue);
    };

    return (
        <span>
            <select value={selectedYear} onChange={handleYearSelect} className="text-black">
                {DASHBOARD_YEARS.map((option, i) => {
                    return (
                        <option key={option} value={option}>
                            {option}-{option%100+1}
                        </option>
                    )
                })}
            </select>
        </span>
    );
};

export default MonthYearComponent;