import Header from "../layout/Header";
import Footer from "../layout/Footer";
import {Link} from "react-router-dom";
import NewsLetter from "../components/NewsLetter";
import {blogs} from "../constants";

const TermsAndConditions = () => {
    return (
        <div className="bg-fixed">
            <Header/>

            <div className="w-[90%] text-white glossy ml-[5%] p-14 terms">
                <p className="text-3xl"><strong>SustainCred Terms of Use</strong></p>
                <p>This Agreement (&ldquo;<strong>Agreement</strong>&ldquo;) sets forth the terms on which SustainCred Limited) (&ldquo;<strong>SustainCred</strong>&ldquo;) will permit you (&ldquo;<strong>Customer</strong>&ldquo;) to use the Services (as defined below).</p>
                <p>The parties agree as follows:</p>
                <ol>
                    <li><strong> DEFINITIONS</strong></li>
                </ol>
                <p>The following definitions apply in this Agreement:</p>
                <p><strong>Confidential Information</strong></p>
                <p><strong>&zwj;</strong>All confidential information disclosed by a Party to the other Party, whether orally or in writing, and whether before or after the Effective Date, which is either designated as confidential by the disclosing party at the time of disclosure or otherwise which would be understood to be confidential given the nature of the information. Confidential Information includes the fact that the Customer is using the Services, all Customer Data and all Reports, as originally compiled by the Services. Confidential Information excludes any aggregated or anonymized information derived by SustainCred from the Reports that is irreversibly disassociated with the Customer.</p>
                <p>&zwj;<strong>Customer Data</strong></p>
                <p><strong>&zwj;</strong>All data submitted by Customer to SustainCred as a result of Customer&rsquo;s use of the Services.</p>
                <p>&zwj;<strong>Documentation</strong></p>
                <p><strong>&zwj;</strong>Any user documentation, in all forms, provided to Customer by SustainCred relating to the Services.</p>
                <p>&zwj;<strong>Effective Date</strong></p>
                <p><strong>&zwj;</strong>The date of this Agreement.</p>
                <p>&zwj;<strong>Intellectual Property Rights</strong></p>
                <p><strong>&zwj;</strong>Patents, rights to inventions, copyright and neighbouring and related rights, trademarks, business names and domain names, rights in get-up, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality of, Confidential Information (including know-how) and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world.</p>
                <p>&zwj;<strong>Parties</strong></p>
                <p><strong>&zwj;</strong>SustainCred and Customer, each referred to respectively as a Party.</p>
                <p>&zwj;<strong>Personal Data</strong></p>
                <p><strong>&zwj;</strong>Any information relating to an identified or identifiable natural person, who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
                <p>&zwj;<strong>Privacy Policy</strong></p>
                <p><strong>&zwj;</strong>SustainCred privacy policy at SustainCred.com/privacy which sets out how Customer&rsquo;s and prospective customers&rsquo; representatives&rsquo; personal data will be processed.</p>
                <p>&zwj;<strong>Reports</strong></p>
                <p><strong>&zwj;</strong>The results and outcomes of the Customer&rsquo;s use of the Services, specifically the greenhouse gas emission reports compiled by the Services on the basis of the Customer Data.</p>
                <p>&zwj;<strong>Services</strong></p>
                <p><strong>&zwj;</strong>The web-based services, made available by SustainCred at&nbsp;<a href="https://emitwise.com/">dash.SustainCred.com/login</a>, specifically the SustainCred platform that calculates Carbon Credits in line with the Greenhouse Gas Protocol for Scope 1, 2 and 3 emissions developed by the World Resources Institute and the World Business Council for Sustainable Development or in line with another methodology.</p>
                <p>&zwj;<strong>Third Party Applications</strong></p>
                <p><strong>&zwj;</strong>Any online applications or offline software products or Services that interoperate with the Services which are not provided by SustainCred.</p>
                <ol start="2">
                    <li><strong> ACCESS TO AND USE OF THE SERVICES</strong></li>
                </ol>
                <p>2.1 Use of the Services. Subject to the terms and conditions of this Agreement, SustainCred grants to Customer a worldwide, non-exclusive, non-transferable (except as permitted by this Agreement), licence, without the right to grant sub-licence, for the Term of this Agreement, to use the Services and the Documentation solely in connection with Customer&rsquo;s internal business operations. Customer&rsquo;s right to use the Services is subject to and contingent upon Customer&rsquo;s compliance with this Agreement and SustainCred reserves all rights not expressly granted herein.</p>
                <p>2.2 Technical Support Services. SustainCred may provide Customer with reasonable technical support.</p>
                <p>2.3 Use Restrictions. Except as otherwise explicitly provided in this Agreement or as may be expressly permitted by applicable law, Customer will not, and will not permit or authorise third parties to:</p>
                <ol>
                    <li>copy, modify, transmit, distribute, frame, mirror, or attempt to reverse engineer, disassemble, reverse compile or otherwise reduce to human-readable form all or any part of the Services and/or Documentation (as applicable), in any form or by any means;</li>
                    <li>rent, lease, sell, transfer, distribute, exploit, or otherwise permit third parties to use the Services or Documentation without SustainCred&rsquo;s written consent;</li>
                    <li>use the Services or Documentation to provide services to third parties without SustainCred&rsquo;s written consent;</li>
                    <li>use the Services or the Reports in any way that purports or suggests that there is any affiliation between the Parties;</li>
                    <li>use the Services to store or transmit any infringing, obscene, defamatory or otherwise unlawful or tortious material, or any material that violates a third party&rsquo;s privacy rights; or</li>
                    <li>interfere with, damage, disrupt the integrity or performance of the Services, including by circumventing or disabling any security or other technological features or measures of the Services;</li>
                </ol>
                <p>2.4 Use Breach. Customer acknowledges that any breach of clause 2.4 (Use Restrictions) shall be treated as a material breach by Customer, entitling SustainCred to terminate Customer&rsquo;s access to the Services.</p>
                <p>2.5 Compliance with Laws. Customer will use the Services, Documentation and the Reports in compliance with all applicable laws and regulations.</p>
                <p>2.6 Protection against Unauthorised Use. Customer will use its best endeavours to prevent any unauthorised use of the Services and Documentation and promptly notify SustainCred in writing of any unauthorised use that comes to Customer&rsquo;s attention. If there is unauthorised use by anyone who obtained access to the Services directly or indirectly through Customer, Customer will take all steps reasonably necessary to terminate the unauthorised use. Customer will cooperate and assist with any actions taken by SustainCred to prevent or terminate unauthorised use of the Services or Documentation.</p>
                <p>2.7 Reservation of Rights. SustainCred grants to Customer a limited right to use the Services and Documentation under this Agreement. Customer will not have any rights to the Services or Documentation except as expressly granted in this Agreement. SustainCred reserves to itself all rights to the Services and Documentation not expressly granted to Customer in accordance with this Agreement.</p>
                <p>2.8 Third Party Applications. Customer acknowledges and agrees that, where it uses Third-Party Applications with the Services, such Third Party Applications may need to access Customer Data in order to interoperate with the Services. Customer acknowledges and agrees that SustainCred shall not be liable for any disclosure, modification, or deletion of Customer Data resulting from any such access by Third Party Applications.</p>
                <p>2.9 Feedback. If Customer provides any feedback to SustainCred concerning the functionality and performance of the Services (including identifying potential errors, modifications and improvements), Customer hereby assigns to SustainCred all rights, title and interest in and to such feedback, including any Intellectual Property Rights therein.</p>
                <ol start="3">
                    <li><strong> FEES</strong></li>
                </ol>
                <p>3.1 The Services are currently available free of charge, but emit SustainCred Wise reserves the right to introduce fees in the future. SustainCred will notify Customer if it intends to introduce a subscription service, and will provide Customer with the opportunity either to upgrade to the subscription-based product or to terminate the Agreement.</p>
                <ol start="4">
                    <li><strong> CUSTOMER OBLIGATIONS AND ACKNOWLEDGEMENTS</strong></li>
                </ol>
                <p>4.1 Customer Obligations. In using the Services, Customer agrees that:</p>
                <ol>
                    <li>the Services are only used for the purposes permitted by this Agreement, and in accordance with the Documentation;</li>
                    <li>where the Services are used in any way that results in the contravention of any laws or regulations (including laws and regulations governing confidentiality and the processing of Personal Data), SustainCred shall not be responsible for such contravention or failure;</li>
                    <li>it is solely responsible for the accuracy of the figures and of any other Customer Data entered into the Services and used as basis for the Reports. SustainCred does not verify the accuracy of any figures or other Customer Data;</li>
                    <li>it is solely responsible for any conclusions, decisions or filings it makes based on the Reports;</li>
                    <li>it is solely responsible for the input and maintenance of the Customer Data and for maintaining effective back-up procedures as may be necessary to replace any Customer Data in the event of loss or damage, regardless of cause;</li>
                    <li>it will promptly furnish SustainCred with written confirmation on request, verifying that the Services are being used in accordance with the terms and conditions of this Agreement.</li>
                    <li>SustainCred shall not be liable for any damage caused by errors or omissions in any information, instructions, scripts, or other Customer Data provided to SustainCred by Customer in connection with the Services, or any action taken by SustainCred at Customer&rsquo;s direction;</li>
                    <li><strong> PROPRIETARY RIGHTS</strong></li>
                </ol>
                <p>5.1 Rights in the Services. SustainCred (and its licensors, where applicable) shall own all right, title and interest in and to the Services and Documentation, including to any and all enhancements, modifications, extensions and derivative works thereof.</p>
                <p>5.2 Customer Data. Customer Data will be used and disclosed solely as required to perform the Services and in accordance with this Agreement. Customer will own Customer Data.</p>
                <p>5.3 Aggregated or Anonymised Data. SustainCred may derive aggregated or anonymized data from the Reports that is irreversibly disassociated with the Customer and use such information for commercial purposes (&ldquo;<strong>SustainCred Data</strong>&ldquo;). SustainCred shall own all right, title and interest in and to the SustainCred Data. For the avoidance of doubt, SustainCred Data shall not be considered Confidential information.</p>
                <p>5.3 Reports. SustainCred shall own all right, title and interest in and to the Reports. SustainCred hereby grants to Customer a worldwide, perpetual, royalty-free, fully paid, transferable sub-licensable and irrevocable right to use the Reports for its commercial purposes in accordance with this Agreement.</p>
                <p>5.4 Other Works. Except for any Customer Data, all Intellectual Property Rights in works of authorship, inventions, discoveries, improvements, methods, processes, formulas, designs, techniques, and information conceived, discovered, developed or otherwise made (as necessary to establish authorship, inventorship, or ownership) by SustainCred, solely or in collaboration with others, in the course of performing the Services, whether developed as part of the Services or separately, will be the sole property of SustainCred.</p>
                <p>5.5 Third Party Products. Any third party products that are provided by SustainCred in connection with the Services are provided pursuant to the terms of the applicable third-party agreement, and SustainCred assumes no responsibility for, and specifically disclaims any liability or obligation with respect to, any third party products.</p>
                <ol start="6">
                    <li><strong> PERSONAL DATA</strong></li>
                </ol>
                <p>6.1 Data Processing. The Customer shall refrain from submitting any Personal Data to SustainCred in the course of using the Services, with the exception of the minimum necessary Personal Data that would enable the SustainCred to provide the Services, specifically, a name, job title, email address and a password. For the purposes of data protection legislation, SustainCred shall act as a controller of such Personal Data and process it in accordance with its Privacy Policy.</p>
                <p><strong>7.CONFIDENTIALITY</strong></p>
                <p>7.1 Non-disclosure. Each Party undertakes that it shall not at any time during this agreement, and for a period of five years after termination of this agreement, disclose to any person any Confidential Information concerning the business, affairs, customers, clients or suppliers of the other party or of any member of the group of companies to which the other party belongs, except as permitted by subsection two of this clause.</p>
                <p>7.2 Allowed disclosure. Each Party may disclose the other Party&rsquo;s Confidential Information: (a) to its employees, officers, representatives or advisers who need to know such information for the purposes of exercising the party&rsquo;s rights or carrying out its obligations under or in connection with this Agreement. Each Party shall ensure that its employees, officers, representatives or advisers to whom it discloses the other party&rsquo;s confidential information comply with this clause; and (b) as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority.</p>
                <p>7.3 Allowed use. No Party shall use any other party&rsquo;s Confidential Information for any purpose other than to exercise its rights and perform its obligations under or in connection with this Agreement.</p>
                <ol start="8">
                    <li><strong> WARRANTIES AND DISCLAIMER</strong></li>
                </ol>
                <p>8.1 Mutual Warranties. Each Party represents and warrants to the other that: this Agreement has been duly executed and delivered and constitutes a valid and binding Agreement enforceable against such Party in accordance with its terms; and no authorisation or approval from any third party is required in connection with such party&rsquo;s execution, delivery, or performance of this Agreement.</p>
                <p>8.2 Customer warranties. Customer warrants that:</p>
                <ol>
                    <li>the Customer Data do not infringe the Intellectual Property Rights or privacy rights of any third party;</li>
                    <li>it has secured all necessary rights, licences, permissions and consents necessary for SustainCred to use the Customer Data in providing the Services;</li>
                    <li>the receipt and use of Customer Data in the performance of this Agreement by SustainCred, its agents, subcontractors or consultants shall not infringe the rights, including any Intellectual Property Rights, of any third party;</li>
                    <li>it has not falsely identified itself nor provided any false information to gain access to the Services;</li>
                    <li>it will not remove any of SustainCred&rsquo;s logos, trademarks, designs, and/or identifying texts, from the Reports;</li>
                    <li>any billing information it provides to SustainCred is correct.</li>
                </ol>
                <p>8.3 Disclaimer. EXCEPT FOR THE EXPRESS REPRESENTATIONS AND WARRANTIES STATED IN CLAUSE 8.1, SUSTAINCRED MAKES NO ADDITIONAL REPRESENTATION OR WARRANTY OR GUARANTEE OF ANY KIND WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR STATUTORY, AS TO ANY MATTER WHATSOEVER. SUSTAINCRED EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE (INCLUDING FOR THE PURPOSE OF ANY REPORTING OBLIGATIONS STEMMING FROM APPLICABLE LAW), QUALITY OR ACCURACY. SUSTAINCRED DOES NOT WARRANT AGAINST INTERFERENCE WITH THE ENJOYMENT OF THE SERVICES. SUSTAINCRED DOES NOT WARRANT THAT THE SERVICES ARE ERROR-FREE OR THAT OPERATION OF THE SERVICES WILL BE SECURE OR UNINTERRUPTED. SUSTAINCRED DOES NOT WARRANT THAT ANY INFORMATION PROVIDED THROUGH THE SUSTAINCRED SERVICES IS ACCURATE OR COMPLETE OR THAT ANY INFORMATION PROVIDED THROUGH THE SERVICES WILL ALWAYS BE AVAILABLE. SUSTAINCRED EXERCISES NO CONTROL OVER AND EXPRESSLY DISCLAIMS ANY LIABILITY ARISING OUT OF OR BASED UPON THE RESULTS OF CUSTOMER&rsquo;S USE OF THE SERVICES.</p>
                <ol start="9">
                    <li><strong> INTELLECTUAL PROPERTY INFRINGEMENT</strong></li>
                </ol>
                <p>9.1 Indemnification of Infringement Claims. Customer will indemnify SustainCred from and pay all damages, costs, losses and expenses (including reasonable legal fees) finally awarded against SustainCred in any claim, proceeding or suit brought by a third party against SustainCred alleging that the Customer Data infringe any Intellectual Property Rights.</p>
                <ol start="10">
                    <li><strong> LIMITATIONS OF LIABILITY</strong></li>
                </ol>
                <p>10.1 Disclaimer of Indirect Damages. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THIS AGREEMENT, SUSTAINCRED WILL NOT, UNDER ANY CIRCUMSTANCES, BE LIABLE TO CUSTOMER FOR ANY REGULATORY FINES, LOSS OF PROFITS, LOSS OF SALES OR BUSINESS, LOSS OF AGREEMENTS OR CONTRACTS, LOSS OF ANTICIPATED SAVINGS, LOSS OF OR DAMAGE TO GOODWILL, LOSS OF USE OR CORRUPTION OF SOFTWARE, DATA OR INFORMATION, OR ANY INDIRECT OR CONSEQUENTIAL LOSS ARISING OUT OF OR RELATED TO THIS AGREEMENT, INCLUDING OUT OF CUSTOMER&rsquo;S USE OF THE SERVICES.</p>
                <p>10.2 Cap on Liability. UNDER NO CIRCUMSTANCES WILL SUSTAINCRED&rsquo;S TOTAL LIABILITY OF ALL KINDS ARISING OUT OF OR RELATED TO THIS AGREEMENT (INCLUDING BUT NOT LIMITED TO WARRANTY CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT, BREACH OF STATUTORY DUTY OR OTHERWISE, EXCEED (A) THE TOTAL AMOUNT PAID BY CUSTOMER TO SUSTAINCRED DURING THE 12 MONTHS IMMEDIATELY PRECEDING THE CLAIM, OR (B) IF NO FEES HAVE BEEN PAID, &pound;100.</p>
                <ol start="11">
                    <li><strong> TERM AND TERMINATION</strong></li>
                </ol>
                <p>11.1 Term. This Agreement will commence on the Effective Date and continue until terminated in accordance with the terms of this Agreement.</p>
                <p>11.2 Termination. SustainCred may terminate this Agreement without notice for any reason. Termination in accordance with this clause 10.2 will take effect when Customer receives written notice of termination from SustainCred. SustainCred may also suspend Customer&rsquo;s access to the Services for any reason without notice to Customer.</p>
                <p>11.3 Post-Termination Obligations. If this Agreement is terminated for any reason, any and all liabilities accrued prior to the effective date of the termination will survive.</p>
                <ol start="12">
                    <li><strong> GENERAL</strong></li>
                </ol>
                <p>12.1 Relationship. Each Party will be and shall act as an independent contractor (and not as the agent or representative of the other Party) in the performance of this Agreement.</p>
                <p>12.2 Assignability. Customer may not assign its right, duties, and obligations under this Agreement without SustainCred&rsquo;s prior written consent.</p>
                <p>12.3 Notices. Any notice required or permitted to be given in accordance with this Agreement will be effective if it is in writing and sent by email to the email address provided by the Parties respectively. Notices sent by email shall be deemed given on the date of transmission.</p>
                <p>12.4 Force Majeure. SustainCred will not be liable for, or be considered to be in breach of or default under this Agreement on account of, any delay or failure to perform as required by this Agreement as a result of any cause or condition beyond its reasonable control.</p>
                <p>12.5 Governing Law and Jurisdiction. This Agreement and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with the law of England and Wales. Each Party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this Agreement or its subject matter or formation.</p>
                <p>12.6 Waiver. The waiver by either Party of any breach of any provision of this Agreement does not waive any other breach. The failure of any Party to insist on strict performance of any covenant or obligation in accordance with this Agreement will not be a waiver of such party&rsquo;s right to demand strict compliance in the future, nor will the same be construed as a novation of this Agreement.</p>
                <p>12.7 Severability. If any part of this Agreement is found to be illegal, unenforceable, or invalid, the remaining portions of this Agreement will remain in full force and effect.</p>
                <p>12.8 Counterparts. This Agreement may be executed in any number of identical counterparts, notwithstanding that the parties have not signed the same counterpart, with the same effect as if the parties had signed the same document. All counterparts will be construed as and constitute the same Agreement. This Agreement may also be executed and delivered by facsimile and such execution and delivery will have the same force and effect of an original document with original signatures.</p>
                <p>12.9 Entire Agreement. This Agreement, including all schedules is the final and complete expression of the agreement between the Parties regarding Customer&rsquo;s use of the Services.</p>
                <p>12.10 Interpretation. The Parties have had an equal opportunity to participate in the drafting of this Agreement and the attached schedules. No ambiguity will be construed against any Party based on a claim that the Party drafted the language. The headings appearing at the beginning of the clauses contained in this Agreement have been inserted for reference purposes only and must not be used to construe or interpret this Agreement. Any reference to any agreement, document, or instrument will mean such agreement, document, or instrument as amended or modified and in effect from time to time in accordance with the terms thereof. Whenever the words &ldquo;include,&rdquo; &ldquo;includes,&rdquo; or &ldquo;including&rdquo; are used in this Agreement, they will be deemed to be followed by the words &ldquo;without limitation.&rdquo; Whenever the words &ldquo;hereunder,&rdquo; &ldquo;hereof,&rdquo; &ldquo;hereto,&rdquo; and words of similar import are used in this Agreement, they will be deemed references to this Agreement as a whole and not to any particular clause, or other provision hereof. The word &ldquo;or&rdquo; is used in the inclusive sense of &ldquo;and/or.&rdquo; The terms &ldquo;or,&rdquo; &ldquo;any&rdquo; and &ldquo;either&rdquo; are not exclusive.</p>
                <p>12.11 Exclusion of implied terms. Any and all terms implied by statute that may apply to this Agreement are hereby expressly excluded to the fullest extent permissible by law.</p>
                <p>&nbsp;</p>
            </div>

            <Footer/>
        </div>
    );
};

export default TermsAndConditions;
