import React, {useEffect, useState} from "react";
import DashboardNav from "./components/DashboardNav";
import Tabs from "rc-tabs";
import "rc-tabs/assets/index.css";
import MonthYearComponent from "./components/MonthYearComponent";
import EnergyConsumption from "./document-components/EnergyConsumption";
import FuelConsumption from "./document-components/FuelConsumption";
import CompanyVehicles from "./document-components/CompanyVehicles";
import SupplyChain from "./document-components/SupplyChain";
import Transportation from "./document-components/Transportation";
import {Link} from "react-router-dom";
import {renderErrorMessage, renderSubErrorMessage} from "./utils";
import {useCookies} from "react-cookie";
import {API_HOST} from "../constants";

const MyDashboard = () => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [eligible, setEligible] = useState(false);
    const [surveyFilled, setSurveyFilled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cookies] = useCookies(['authtoken']);
    const [errorMessages, setErrorMessages] = useState({});
    useEffect(() => {
        fetchData();
    }, []);
    const isDesktop = window.matchMedia("(min-width: 768px)").matches;
    const DASHBOARD_ITEMS = [
        {
            label: 'Energy Consumption',
            key: 'energy',
            children: <EnergyConsumption year={selectedYear}/>
        },
        {
            label: 'Fuel Consumption',
            key: 'fuel',
            children: <FuelConsumption year={selectedYear}/>
        },
        {
            label: 'Company Vehicles',
            key: 'company',
            children: <CompanyVehicles year={selectedYear}/>
        },
        {
            label: 'Transportation',
            key: 'transport',
            children: <Transportation year={selectedYear}/>
        },
        {
            label: 'Supply Chain',
            key: 'supply',
            children: <SupplyChain year={selectedYear} section={"Supply Chain Details"} section_id={"supply_chain"}/>
        },
        {
            label: 'Finance',
            key: 'finance',
            children: <SupplyChain year={selectedYear} section={"Finance Details"} section_id={"finance"}/>
        },
    ]
    const fetchData = async () => {
        try {
            setLoading(true)
            fetch(`${API_HOST}/accounts/check-eligibility/`, {
                method: "POST",
                headers: {
                    'Authorization': 'Token ' + cookies.authtoken
                }
            }).then((response) => {
                return new Promise((resolve) => response.json()
                    .then((json) => resolve({
                        status: response.status,
                        json,
                    })));
            }).then(({status, json}) => {
                if (status !== 200) setErrorMessages(json)
                else {
                    if (json.eligible) {
                        setEligible(json.eligible)
                        setSurveyFilled(true)
                    }
                    setSurveyFilled(json.completed)
                }
            });
        } catch (error) {
            setErrorMessages({detail: error.message});
        } finally {
            setLoading(false);
        }
    };
    const handleDropdownChange = (month, year) => {
        setSelectedYear(year);
    };
    return (
        <>
            <div className="">
                <div className="center-dashboard glossy">
                    {loading ? (<div>Checking Eligibility && Getting Data...</div>) : ""}
                    {!surveyFilled ? (
                        <div> You have not completed the survey to be eligible for carbon credits. Please proceed to
                            <Link to={"/company"} className="text-yellow-300 hover:underline"> fill in questioner
                                here.</Link>
                        </div>
                    ) : ""}
                    {surveyFilled && !eligible ? (
                        <div>Sorry!!! You are not eligible for carbon credits.</div>
                    ) : ""}
                    {surveyFilled && eligible ? (
                        <div>
                            <div className="float-right">Select Financial year :
                                <MonthYearComponent onSelect={handleDropdownChange}/>
                            </div>
                            <br/>
                            <br/>
                            <Tabs items={DASHBOARD_ITEMS} tabPosition={isDesktop ? "top" : "left"}/>
                        </div>
                    ) : ""}
                    {renderErrorMessage('detail', errorMessages)}
                    {renderSubErrorMessage('error', 'user', errorMessages)}
                </div>
                <DashboardNav/>
            </div>
        </>
    );
};

export default MyDashboard;