const questionsConfig = [
    {
        id: 1, //keep this unique
        question: "What industry is your company in",
        type: "radio",
        options: ["Energy sector", "CH4 mitigation sector ( Venting and flaring removal)",
            "DAC or CCUS", "None of the above"],
        endOnOption: [4],
        required: true
    },
    {
        id: 2,
        question: "What sub-sector is your company  in ?",
        type: "radio",
        options: ["Solar", "Wind", "Hydro", "Geo Thermal", "Tidal Energy", "CH4 Venting Removal",
            "CH4 Flaring Removal", "CCUS", "DAC", "None of the above"],
        endOnOption: [10],
        required: true
    },
    {
        id: 3,
        question: "Which country your operations are located in?",
        type: "radio",
        options: ["Classified region/country as “least developed” by World Bank", "India", "China",
            "Other developing countries as classified by world bank",
            "Developed country as classified by world bank"],
        endOnOption: ["Developed country as classified by world bank"],
        required: true
    },
    {
        id: 4,
        question: "What activities does your company perform within your sector?",
        type: "radio",
        options: ["Selling sub-parts and semi finished parts required for enabling renewable energy sector ie., " +
        "suppliers to renewable energy generation company",
            "Distribution of solar panels or windmills etc",
            "Generation of renewable energy",
            "Renewable energy sale to the grid"],
        endOnOption: [3,4],
        required: true
    },
    {
        id: 5,
        question: "Do you have data available for the following, click all that applies:",
        type: "radio",
        options: ["Electricity usage", "Material used",
            "Waste generated in operations",
            "Transportation",
            "Sale of products",
            "None of the above"],
        required: true
    }
];

export default questionsConfig;

/*Other supported Types: select, textarea
{
    id: 2,
    question: "How often do you exercise?",
    type: "select",
    options: ["Never", "Rarely", "Regularly", "Daily"],
},
{
    id: 3,
    question: "Any additional comments?",
    type: "textarea",
}*/