import React, {useEffect, useState} from "react";
import DashboardNav from "./components/DashboardNav";
import {API_HOST} from "../constants";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";
import {renderErrorMessage, renderSubErrorMessage} from "./utils";
import {Chart} from 'react-charts'

const MyDashboard = () => {

    const [data, setData] = useState(null);

    const series = React.useMemo(
        () => ({
            type: 'bar'
        }),
        []
    )
    const axes = React.useMemo(
        () => [
            {primary: true, position: 'bottom', type: 'ordinal'},
            {position: 'left', type: 'linear', stacked: true},
        ],
        []
    )

    const [eligible, setEligible] = useState(false);
    const [surveyFilled, setSurveyFilled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cookies] = useCookies(['authtoken']);
    const [errorMessages, setErrorMessages] = useState({});
    useEffect(() => {
        checkEligibility();
    }, []);

    const getData = () => {
        fetch(`${API_HOST}/accounts/dashboards/get/`, {
            method: "POST",
            headers: {
                'Authorization': 'Token ' + cookies.authtoken
            }
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    json,
                })));
        }).then(({status, json}) => {
            if (status !== 200) {
                setErrorMessages(json);
            } else {
                setData(json);
            }
        });
    }

    const checkEligibility = async () => {
        try {
            setLoading(true)
            fetch(`${API_HOST}/accounts/check-eligibility/`, {
                method: "POST",
                headers: {
                    'Authorization': 'Token ' + cookies.authtoken
                }
            }).then((response) => {
                return new Promise((resolve) => response.json()
                    .then((json) => resolve({
                        status: response.status,
                        json,
                    })));
            }).then(({status, json}) => {
                if (status !== 200) {
                    setErrorMessages(json)
                } else {
                    if (json.eligible) {
                        setEligible(json.eligible)
                        setSurveyFilled(true)
                        getData();
                    }
                    setSurveyFilled(json.completed)
                }
            });
        } catch (error) {
            setErrorMessages({detail: error.message});
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="">
                <div className="center-dashboard glossy">
                    {loading ? (<div>Checking Eligibility && Getting Data...</div>) : ""}
                    {!surveyFilled ? (
                        <div> You have not completed the survey to be eligible for carbon credits. Please proceed to
                            <Link to={"/company"} className="text-yellow-300 hover:underline"> fill in questioner
                                here.</Link></div>
                    ) : ""}
                    {surveyFilled && !eligible ? (
                        <div>Sorry!!! You are not eligible for carbon credits.</div>
                    ) : ""}
                    {!(data?.energy && data.energy[0]?.data?.length) && !(data?.fuel && data.fuel[0]?.data?.length) ? (
                        <div>
                            Please submit Your Carbon Consumption in
                            <Link to={"/company"} className="text-yellow-300 hover:underline"> My Documents.</Link>
                            <br/><br/>
                        </div>
                        ) : ""}
                    {surveyFilled && eligible && data ? (
                        <div>
                            {data.energy && data.energy[0]?.data?.length ? (
                                <div className="h-64" style={{stroke: "white"}}>
                                    <b className="text-xl">Energy Consumption</b><br/>
                                    <Chart data={data.energy} series={series} axes={axes} tooltip/>
                                </div>
                            ) : ""}
                            <br/><br/><br/>
                            {data.fuel && data.fuel[0]?.data?.length ? (
                                <div className="h-64" style={{stroke: "white"}}>
                                    <b className="text-xl">Fuel Consumption</b><br/>
                                    <Chart data={data.fuel} series={series} axes={axes} tooltip/>
                                </div>
                            ) : ""}
                        </div>
                    ) : ""}
                    {renderErrorMessage('detail', errorMessages)}
                    {renderSubErrorMessage('error', 'user', errorMessages)}
                </div>
                <DashboardNav/>
            </div>
        </>
    );
};

export default MyDashboard;