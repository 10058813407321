import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';

export const INPUT_TYPES = {
    IMAGES: 'IMAGES',
    SHEETS: 'SHEETS',
    SHEETS_PDF: 'SHEETS_PDF'
}
//https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const CONTENT_TYPES = {
    IMAGES: {
        'image/*': []
    },
    SHEETS: {
        'text/csv': [],
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        'application/vnd.oasis.opendocument.spreadsheet': []
    },
    SHEETS_PDF:{
        'text/csv': [],
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        'application/vnd.oasis.opendocument.spreadsheet': [],
        'application/pdf':[]
    }
}
const FILE_DESCRIPTION = {
    IMAGES: 'Any Image(Jpg, Png, Gif, Webp)',
    SHEETS: 'Sheets(Excel, Csv, Odf)',
    SHEETS_PDF: 'Sheets(Excel, Csv, Odf) or Pdf'
}
const FileUploadInput = ({
                             onChange,
                             inputType = INPUT_TYPES.IMAGES,
                             maxFiles = 12,
                             maxSize = 2 * 1024 * 1024,
                             togglePreview
                         }) => {
    const [files, setFiles] = useState([]);
    const [incorrectFiles, setIncorrectFiles] = useState([]);
    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);
    useEffect(() => {
        setFiles([])
    }, [togglePreview]);
    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        accept: CONTENT_TYPES[inputType],
        maxFiles: maxFiles,
        maxSize: maxSize,
        onDropRejected: rejectedFiles => {
            const allFiles = rejectedFiles.concat(incorrectFiles)
            const uniqueFiles = Array.from(new Set(allFiles.map(obj => obj.name))).map(name => {
                return allFiles.find(obj => obj.name === name);
            });
            setIncorrectFiles(uniqueFiles)
        },
        onDrop: acceptedFiles => {
            const allFiles = acceptedFiles.concat(files)
            const uniqueFiles = Array.from(new Set(allFiles.map(obj => obj.name))).map(name => {
                return allFiles.find(obj => obj.name === name);
            });
            if (inputType === INPUT_TYPES.IMAGES) {
                uniqueFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            }
            setFiles(uniqueFiles);
            onChange(uniqueFiles);
        }
    });

    const getThumbnails = () => {
        const acceptedFileItems = files.map(file => (
            <li key={file.path}>
                {file.path} - {(file.size / (1024)).toFixed(2)} KB
            </li>
        ));
        const thumbs = files.map(file => (
            <div className="thumbnail" key={file.name}>
                <div className="upload-container">
                    <img
                        src={file.preview}
                        className="thumbnail-image"
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                            URL.revokeObjectURL(file.preview)
                        }}
                    />
                    <p className="absolute bg-black pl-2 pr-2 pt-1 pb-1" onClick={() => {
                        setFiles(files.filter(item => item !== file))
                    }}>x</p>
                </div>
            </div>
        ));
        return <span>
           {(inputType === INPUT_TYPES.IMAGES) ? (
               <div>{thumbs}</div>
           ) : (acceptedFileItems.length > 0 &&
               (<div className="text-green-400">
                   <h4><b>Accepted files</b></h4>
                   <ul className="list-decimal ml-3">{acceptedFileItems}</ul>
               </div>)
           )}
       </span>
    };

    const getRejectedItems = () => {
        const fileRejectionItems = incorrectFiles.map(({file, errors}) => (
            <li key={file.path}>
                {file.path} - {(file.size / (1024)).toFixed(2)} KB
                <ul className="list-disc ml-4">
                    {errors.map(e => (
                        <li key={e.code}>{e.message}</li>
                    ))}
                </ul>
            </li>
        ));
        return <span>
            {fileRejections.length > 0 && (
                <div className="text-red-400">
                    <h4><b>Rejected files</b></h4>
                    <ul className="list-decimal ml-3">{fileRejectionItems}</ul>
                </div>
            )}
        </span>
    };

    return (
        <div>
            <section className="drop-container">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>Drag & Drop some files here (or) Click to select files</p>
                    <p>You can select upto {maxFiles} files, each file size limited
                        to {(maxSize / (1024 * 1024)).toFixed(2)} MB</p>
                    <p>Supported formats: {FILE_DESCRIPTION[inputType]}</p>
                </div>
                <aside className="thumbnail-container">
                    {getThumbnails()}
                </aside>
                <div>
                    {getRejectedItems()}
                </div>
            </section>
        </div>
    );
}
export default FileUploadInput;