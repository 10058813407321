import Header from "../layout/Header";
import {useState} from "react";
import treeMD from "../assets/logo.png";
import {API_HOST} from "../constants"
import {useCookies} from 'react-cookie';
import {Link, useNavigate} from "react-router-dom";
import user from "../assets/icons/user.svg";
import password from "../assets/icons/password.svg";

const Login = () => {
    const [errorMessages, setErrorMessages] = useState({});
    const [cookies, setCookie] = useCookies(['authtoken', 'expiry', 'email', 'name']);

    let navigate = useNavigate();

    const searchParams = new URLSearchParams(window.location.search);
    const verified = searchParams.get('verified');
    const passwordChanged = searchParams.get('password-change');
    const temporary = searchParams.get('temporary');

    const handleUnexpected = (param) => {

    };
    const handleLogin = (event) => {
        event.preventDefault();

        const {uname, pass} = document.forms['login'];

        const data = {
            email: uname.value,
            password: pass.value
        }
        if (temporary === "true") {
            data.temporary_password = pass.value
        }
        fetch(`${API_HOST}/accounts/login/`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    ok: response.ok,
                    json,
                })));
        }).then(({status, json, ok}) => {
            switch (status) {
                case 400:
                case 404:
                    setErrorMessages({
                        pass: json.detail || json.non_field_errors,
                    });
                    break;
                case 201:
                case 200:
                    setErrorMessages({});
                    setCookie("authtoken", json.token);
                    setCookie("expiry", json.expiry);
                    setCookie("email", json.user.email);
                    setCookie("name", json.user.first_name);
                    navigate(temporary === "true" ? "/change-password?reset=true" : "/dashboard");
                    break;
                case 500:
                default:
                    setErrorMessages({})
                    handleUnexpected({status, json, ok});
            }
        });
    };

    const renderErrorMessage = (name) =>
        errorMessages[name] && (<div className="text-red-700">{errorMessages[name]}</div>);


    return (
        <>
            <div className="bg-fixed -z-50">
                <Header radius="xl:rounded-bl-full xl:rounded-br-full"/>
                <div className="flex">
                    <div className="login-form m-auto grid grid-flow-col auto-cols-max  mt-28 mb-10 rounded-3xl">
                        <div
                            className="absolute -mt-12 md:-mt-16 -ml-12 md:-ml-16 left-1/2 rounded-full">
                            <img src={treeMD} className="w-24 h-24 md:w-32 md:h-32" alt="Logo"/>
                        </div>
                        <div className="form bg-white rounded-3xl">
                            <form className="p-12 pb-2 md:p-24 md:pb-4 registration" name="login"
                                  onSubmit={handleLogin}>
                                {temporary === "true" ? (
                                    <p className="text-red-700">An email has been sent to your email.<br/> Please login using instructions on email.</p>) : ""}
                                {verified === "true" ? (
                                    <p className="text-red-700">Registration completed successfully.<br/> Please Login
                                        now.</p>) : ""}
                                {passwordChanged === "true" ? (
                                    <p className="text-red-700">Password Changed.<br/> Please login again.</p>) : ""}
                                <div className="flex flex-col items-center text-2xl p-3">
                                    <p className="w-auto">Login</p>
                                </div>
                                <div className="flex items-center text-lg ">
                                    <img className="absolute ml-3" src={user} width="24" height="24"/>
                                    <input type="text" name="uname"
                                           className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                           placeholder="Email Address"/>
                                </div>
                                {renderErrorMessage("uname")}
                                <div className="flex items-center text-lg mt-6 md:mt-8">
                                    <img className="absolute ml-3" src={password} width="24" height="24"/>
                                    <input type="password" name="pass"
                                           className="bg-gray-200 rounded pl-12 py-2 md:py-4 focus:outline-none w-full"
                                           placeholder="Password"/>
                                </div>
                                {renderErrorMessage("pass")}
                                <div className="mt-6">
                                    <input
                                        className="bg-gradient-to-b from-gray-700 to-gray-900 font-medium p-2 md:p-4 text-white uppercase w-full rounded"
                                        type="submit"/>
                                </div>
                                <p className="text-center text-sm text-gray-500 mt-4">Don&#x27;t have an account yet?
                                    <Link to={"/register"}
                                          className="font-semibold text-gray-600 hover:underline focus:text-gray-800 focus:outline-none">
                                        Sign up</Link>
                                </p>
                                <p className="text-center text-sm text-gray-500 mt-4">
                                    <Link to={"/forgot-password"}
                                          className="font-semibold text-gray-600 hover:underline focus:text-gray-800 focus:outline-none">
                                        Forgot password?</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;