import {API_HOST, validateInput} from "../../constants";
import FileUploadInput from "./FileUploadInput";
import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {renderErrorMessage} from "../utils";

const EnergyConsumption = ({year}) => {
    const [cookies] = useCookies(['authtoken']);
    const [files, setFiles] = useState([]);
    const [energy, setEnergy] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [preview, setPreview] = useState(true);
    const [errorMessages, setErrorMessages] = useState({});

    const fetchData = async () => {
        try {
            const formData = new FormData();
            formData.append('financial_year', year)
            const response = await fetch(`${API_HOST}/accounts/energy-consumption/get/`, {
                method: "POST",
                headers: {
                    'Authorization': 'Token ' + cookies.authtoken
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const result = await response.json();
            setData(result);
        } catch (error) {
            setErrorMessages({previous_data: error.message});
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [year]);

    const submit = () => {
        const formData = new FormData();
        formData.append('financial_year', year)
        formData.append('consumption', energy)
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        fetch(`${API_HOST}/accounts/energy-consumption/update/`, {
            method: "POST",
            headers: {
                'Authorization': 'Token ' + cookies.authtoken
            },
            body: formData
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    json,
                })));
        }).then(({status, json}) => {
            if (status !== 200) setErrorMessages(json)
            else {
                fetchData();
                setFiles([])
                setErrorMessages({detail: 'Data is submitted successfully'})
                setPreview(!preview)
            }
        });
    }

    const setEnergyValue = (event) => {
        validateInput(event);
        setEnergy(event.target.value);
    }

    return (
        <div className="text-left p-2">

            <div className="p-2">
                <p className="text-center">Please update Energy Consumption for Financial Year {year}. </p><br/>
                <div>
                    <span>Input consumption for the year in kw/h :  </span>
                    <input placeholder="Energy in kw/h" onKeyPress={validateInput} onChange={setEnergyValue} type="number"
                           className="bg-[#999999] w-18" min="0" max="99999" autoFocus={true}/>
                    {renderErrorMessage('consumption', errorMessages)}
                    <br/><br/>
                </div>

                <FileUploadInput onChange={setFiles} togglePreview={preview}/>
                {renderErrorMessage('files', errorMessages)}

                <center>
                    <button onClick={() => submit()} className="bg-green-700 rounded-2xl py-2 px-8 text-xl">Submit
                    </button>
                    <br/>
                    {renderErrorMessage('detail', errorMessages)}
                    {renderErrorMessage('non_detail_fields', errorMessages)}
                    {data ? (<p>Note: Submit will override any consumption updated previously</p>) : ""}
                </center>
            </div>
            {data ?
                (<span>
                    <hr className="m-3"/>
                    <p className="text-center">Previously Submitted Energy Consumption
                        for financial {year} </p>
                    <br/>
                    <div className="w-full" style={{overflowX: "auto"}}>
                    <table className="bills">
                        <thead>
                        <tr>
                            <th>Consumption:</th>
                            <th>Updated On:</th>
                            <th>Supporting Documents:</th>
                        </tr>

                        </thead>
                        <tbody>
                        <tr>
                            <td>{data.consumption} kw/h</td>
                            <td>{data.update_time}</td>
                            <td>
                                <ul className="list-disc ml-3">
                                    {JSON.parse(data.files_list?.replace(/'/g, '"'))?.map((option, i) => {
                                        return (
                                            <li key={i}>
                                                <a href={"server/media/" + option} target={"_blank"}
                                                   className="text-yellow-300 hover:underline">{option}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </span>) : ""}
            {loading ? (<div>Loading...</div>) : ""}
            {renderErrorMessage('previous_data', errorMessages)}
        </div>
    );
}
export default EnergyConsumption;