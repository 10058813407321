import React, {useEffect, useState} from "react";
import questionsConfig from "./configs/MyCompanyConfig";
import DashboardNav from "./components/DashboardNav";
import {useCookies} from "react-cookie";
import {API_HOST} from "../constants";
import {renderErrorMessage, renderSubErrorMessage} from "./utils";
import {Link} from "react-router-dom";

const MyCompany = () => {
    const [cookies] = useCookies(['authtoken']);
    const [formData, setFormData] = useState({});
    const [validationErrors, setValidationErrors] = useState("");
    const [question, setQuestion] = useState(questionsConfig[0]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentAnswer, setCurrentAnswer] = useState("");
    const [eligible, setEligible] = useState(true);
    const [surveyEligible, setSurveyEligible] = useState(false);
    const [surveyFilled, setSurveyFilled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const totalQuestions = questionsConfig.length;
    const [errorMessages, setErrorMessages] = useState({});

    const handleInputChange = (questionId, value) => {
        setCurrentAnswer(value)
        setValidationErrors("")
        setFormData((prevData) => ({
            ...prevData,
            [questionId]: value,
        }));
    };

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            setLoading(true)
            fetch(`${API_HOST}/accounts/check-eligibility/`, {
                method: "POST",
                headers: {
                    'Authorization': 'Token ' + cookies.authtoken
                }
            }).then((response) => {
                return new Promise((resolve) => response.json()
                    .then((json) => resolve({
                        status: response.status,
                        json,
                    })));
            }).then(({status, json}) => {
                if (status !== 200) setErrorMessages(json)
                else {
                    if (json.eligible) {
                        setSurveyEligible(json.eligible)
                        setSurveyFilled(true)
                    }
                    setSurveyFilled(json.completed)
                }
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e, eligible) => {
        let questionAnswers = []
        questionsConfig.forEach((question) => {
            questionAnswers.push({
                id: question.id,
                question: question.question,
                answer: formData[question.id]
            })
        });
        const data = {
            eligible: eligible,
            answers: questionAnswers
        }
        fetch(`${API_HOST}/accounts/survey-questions/update/`, {
            method: "POST",
            headers: {
                'Authorization': 'Token ' + cookies.authtoken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((response) => {
            return new Promise((resolve) => response.json()
                .then((json) => resolve({
                    status: response.status,
                    json,
                })));
        }).then(({status, json}) => {
            if (status !== 200) setErrorMessages(json)
            else {
                setErrorMessages({detail: 'Data is submitted successfully'})
                setSurveyEligible(true)
                setSurveyFilled(true)
            }
        });
    };

    const handleQuestion = (next) => {
        if (next && question.required && !currentAnswer && !formData[question.id]) {
            setValidationErrors("Please submit an option");
            return
        }
        let i = 0;
        let shouldBreak = false;
        question?.options?.map((option) => {
            i++;
            if (currentAnswer != null && (option === currentAnswer || option === formData[question.id])) {
                let maxEndOfQuestions = question.endOnOption?.length || 0;
                for (let j = 0; j < maxEndOfQuestions; j++) {
                    if (question.endOnOption[j] === i) {
                        setEligible(false);
                        setCurrentAnswer(null)
                        shouldBreak = true;
                        return;
                    }
                }
            }
        })
        if (shouldBreak) {
            return;
        }
        setCurrentAnswer("")
        setEligible(true)
        if (!eligible && next === false) {
            setCurrentQuestion(currentQuestion);
            setQuestion(questionsConfig[currentQuestion])
        } else {
            setCurrentQuestion(question => next ? question + 1 : question - 1);
            setQuestion(questionsConfig[next ? currentQuestion + 1 : currentQuestion - 1])
        }
    }

    const handleNext = (e) => {
        e.preventDefault();
        handleQuestion(true);
    };
    const handlePrevious = (e) => {
        e.preventDefault();
        handleQuestion(false);
    };

    const reTakeSurvey = () => {
        setSurveyFilled(false);
    };
    return (
        <>
            <div className="">
                <div className="center-dashboard glossy">
                    {loading ? (<div>Loading Survey...</div>) : ""}
                    {surveyFilled && surveyEligible ? (
                        <div> You have completed the survey.<br/> You are eligible for carbon credits. Please proceed to
                            <Link to={"/documents"} className="text-yellow-300 hover:underline"> Documents tab to upload
                                documents</Link> & get carbon credits.</div>
                    ) : ""}
                    {surveyFilled && !surveyEligible ? (
                        <div>
                            <p>
                                You have completed the survey.<br/>
                                Sorry!!! You are not eligible for carbon credits based on the answers you have submitted
                            </p>
                            <br/>
                            <br/>
                            <p>
                                Is there a mistake? Would you like to retake the survey questions?
                            </p>
                            <button className="bg-green-700 rounded-2xl py-2 px-3"
                                    onClick={() => reTakeSurvey()}>Retake Survey</button>
                        </div>
                    ) : ""}
                    {error ? (<div>Error loading Survey Data: {error}</div>) : ""}
                    {!loading && !surveyFilled ?
                        <div>
                            {eligible && question && (
                                <div>
                                    <div key={question.id}>
                                        <label
                                            className="text-2xl font-bold">Q{question.id}) {question.question}</label>
                                        {question.type === "radio" && (
                                            <div>
                                                {question.options.map((option) => (
                                                    <div key={option}>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                name={`question_${question.id}`}
                                                                value={option}
                                                                checked={formData[question.id] === option}
                                                                onChange={(e) => handleInputChange(question.id, e.target.value)}
                                                            /> &nbsp;
                                                            {option}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {question.type === "select" && (
                                            <select
                                                value={formData[question.id] || ""}
                                                onChange={(e) => handleInputChange(question.id, e.target.value)}
                                            >
                                                <option value="">Select an option</option>
                                                {question.options.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                        {question.type === "textarea" && (
                                            <textarea
                                                value={formData[question.id] || ""}
                                                onChange={(e) => handleInputChange(question.id, e.target.value)}
                                            />
                                        )}
                                    </div>
                                    <div className="text-amber-400">{validationErrors}</div>
                                    <br/>
                                </div>
                            )}
                            <div>
                                {(!question && eligible) && (
                                    <div>
                                        <div>
                                            Thank you for filling the questionare. Please review the data & submit the
                                            form.<br/>
                                            {Object.keys(formData).map(key =>
                                                <p key={key}>{key} : {formData[key]}</p>
                                            )}
                                            <br/><br/>
                                        </div>
                                        <button onClick={() => handleSubmit(this, true)}
                                                className="bg-green-700 rounded-2xl py-2 px-3 float-right">Submit
                                        </button>
                                    </div>
                                )}
                                {(!eligible) && (
                                    <div>
                                        Thank you for your responses.<br/> Based on the information you provided,
                                        you are not eligible for carbon credits and we won't be able to to proceed
                                        further.
                                        <br/><br/><br/>
                                        <button onClick={() => handleSubmit(this, false)}
                                                className="bg-green-700 rounded-2xl py-2 px-3 float-right">Done
                                        </button>
                                    </div>
                                )}
                                {((eligible && currentQuestion >= 0) && (totalQuestions !== currentQuestion)) &&
                                    (<button onClick={handleNext}
                                             className="bg-green-700 rounded-2xl py-2 px-3 float-right">Next</button>)}
                                {((totalQuestions >= currentQuestion && currentQuestion !== 0) || !eligible) && (
                                    <button onClick={handlePrevious}
                                            className="bg-green-700 rounded-2xl py-2 px-3">Previous</button>
                                )}
                            </div>
                            {renderErrorMessage('detail', errorMessages)}
                            {renderSubErrorMessage('error', 'user', errorMessages)}
                        </div>
                        : ""}
                </div>
                <DashboardNav/>
            </div>
        </>
    );
};

export default MyCompany;